import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  RouteRecordRaw,
} from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/research/price",
        name: "apps-price-list",
        props: true,
        component: () => import("@/views/apps/research/PriceTemplateListView.vue"),
      },
      {
        path: "/research/price/add",
        name: "apps-price-add",
        component: () => import("@/views/apps/research/AddPriceTemplateModal.vue"),
      },
      {
        path: "/entity-profile",
        name: "apps-profile-listing",
        component: () => import("@/views/apps/userProfile/UserProfileList.vue"),
      },
      {
        path: "/entity-profile/add",
        name: "apps-profile-add",
        component: () => import("@/views/apps/userProfile/UserProfileAdd.vue"),
      },
      {
        path: "/entity-profile/entity-profile-details/:id",
        name: "apps-profile-details",
        props: true,
        component: () => import("@/views/apps/userProfile/UserProfileDetails.vue"),
      },
      {
        path: "/entity-profile/entity-individual-profile-details/:id",
        name: "apps-profile-ind-details",
        props: true,
        component: () => import("@/views/apps/userProfile/UserProfileIndividualDetails.vue"),
      },
      {
        path: "/entity-profile/entity-plant-details/:id",
        name: "apps-profile-plant-details",
        props: true,
        component: () => import("@/views/apps/userProfile/UserProfilePlantsDetails.vue"),
      },
      {
        path: "/entity-upload",
        name: "apps-upload-listing",
        component: () => import("@/views/apps/userProfile/EntityDataList.vue"),
      },
      {
        path: "/entity-upload/entity-data-details/:id",
        name: "apps-entity-edit",
        props: true,
        component: () => import("@/views/apps/userProfile/EntityDataEdit.vue"),
      },
      {
        path: "/profiledetail",
        name: "apps-companies-detaills",
        component: () => import("@/views/apps/companies/CompaniesDetails.vue"),
      },
      {
        path: "/research/price-upload",
        name: "price-upload-listing",
        component: () => import("@/views/apps/price/PriceListView.vue"),
      },
      {
        path: "/products",
        name: "apps-product-listing",
        component: () => import("@/views/apps/product/Products.vue"),
      },
      {
        path: "/CreateProduct",
        name: "apps-product-create",
        component: () => import("@/views/apps/product/CreateProductNew.vue"),
      },
      {
        path: "/product-details/:id",
        name: "apps-product-details",
        props: true,
        component: () => import("@/views/apps/product/ProductDetails.vue"),
      },
      {
        path: "/products/categories",
        name: "apps-categories-listing",
        props: true,
        component: () => import("@/views/apps/product/ProductCategories.vue"),
      },
      {
        path: "/products/categories/categories-details/:id",
        name: "apps-categories-details",
        props: true,
        component: () =>
          import("@/views/apps/product/ProductCategoriesDetails.vue"),
      },
      {
        path: "/products/product-attribute",
        name: "apps-attribute-listing",
        props: true,
        component: () => import("@/views/apps/product/AttributeList.vue"),
      },
      {
        path: "/products/product-standard",
        name: "apps-product-standard-listing",
        component: () => import("@/views/apps/product/ProductStandard.vue"),
      },
      {
        path: "/products/add-manufacturer-config/:id",
        props: true,
        name: "apps-product-manufacturer-config",
        component: () => import("@/views/apps/product/AddManufacturerConfig.vue"),
      },
      {
        path: "/products/add-plant-capability-config/:id",
        props: true,
        name: "apps-plant-capability-config",
        component: () => import("@/views/apps/product/AddPlantCabalityMatrix.vue"),
      },
      // {
      //   path: "/products/add-manufacturer-config/",
      //   name: "apps-product-manufacturer-config",
      //   component: () => import("@/views/apps/product/AddManufacturerConfig.vue"),
      // },
      {
        path: "/products/product-standard-details/:id",
        name: "apps-product-standard-details-listing",
        props: true,
        component: () =>
          import("@/views/apps/product/ProductStandardDetails.vue"),
      },
      {
        path: "/products/product-grade",
        name: "apps-product-grade-listing",
        component: () => import("@/views/apps/product/ProductGrade.vue"),
      },
      {
        path: "/products/product-hsn",
        name: "apps-product-hsn-listing",
        component: () => import("@/views/apps/product/ProductHsn.vue"),
      },
      {
        path: "/products/product-hsn-details/:id",
        name: "apps-product-hsn-details",
        props: true,
        component: () => import("@/views/apps/product/ProductHsnDetails.vue"),
      },
      {
        path: "/products/product-grade-details/:id",
        name: "apps-grade-details",
        props: true,
        component: () => import("@/views/apps/product/ProductGradeDetails.vue"),
      },
      {
        path: "/products/product-brand",
        name: "apps-product-brand-list",
        props: true,
        component: () => import("@/views/apps/product/ProductBrand.vue"),
      },
      {
        path: "/products/product-brand-details/:id",
        name: "apps-product-brand-details",
        props: true,
        component: () => import("@/views/apps/product/ProductBrandDetails.vue"),
      },
      {
        path: "/groups",
        name: "apps-groups-listing",
        component: () =>
          import("@/views/apps/financialAccounting/GroupsList.vue"),
      },
      {
        path: "/groups/groups-details/:id",
        name: "apps-groups-details-listing",
        props: true,
        component: () =>
          import("@/views/apps/financialAccounting/GroupsDetails.vue"),
      },
      {
        path: "/journaltypes",
        name: "apps-journaltypes-listing",
        component: () =>
          import("@/views/apps/financialAccounting/JournalTypesList.vue"),
      },
      {
        path: "/account",
        name: "apps-account-listing",
        component: () =>
          import("@/views/apps/financialAccounting/AccountsList.vue"),
      },
      {
        path: "/account/AccountsPayableReport/",
        name: "apps-account-payable-listing",
        component: () =>
          import("@/views/apps/financialAccounting/AccountPayableReport.vue"),
      },
      {
        path: "/account/AccountsReceivableReport/",
        name: "apps-account-receivable-listing",
        component: () =>
          import("@/views/apps/financialAccounting/AccountReceivableReport.vue"),
      },
      {
        path: "/account",
        name: "apps-account-listing",
        component: () =>
          import("@/views/apps/financialAccounting/AccountsList.vue"),
      },

      {
        path: "/journal",
        name: "apps-journal-listing",
        component: () =>
          import("@/views/apps/financialAccounting/JournalEntriesList.vue"),
      },

      {
        path: "/journalcreate",
        name: "apps-journalcreate-listing",
        component: () =>
          import("@/views/apps/financialAccounting/JournalEntriesCreate.vue"),
      },
      {
        path: "/editJournal/:id",
        name: "apps-journal-edit",
        props: true,
        component: () =>
          import("@/views/apps/financialAccounting/JournalEntriesView.vue"),
      },
      {
        path: "/reports/purchaseOrderStatusReport/",
        name: "apps-purchase-orders-status-report-listing",
        component: () => import("@/views/apps/reports/PurchaseOrderStatus.vue"),
      },
      {
        path: "/reports/salesOrderStatusReport/",
        name: "apps-sales-orders-status-report-listing",
        component: () => import("@/views/apps/reports/SalesOrderStatus.vue"),
      },
      {
        path: "/ledger",
        name: "apps-report-listing",
        component: () => import("@/views/apps/reports/Ledger.vue"),
      },
      {
        path: "/salesledger",
        name: "apps-sales=report-listing",
        component: () => import("@/views/apps/reports/SalesLedger.vue"),
      },
      {
        path: "/purchaseledger",
        name: "apps-purchase-report-listing",
        component: () => import("@/views/apps/reports/PurchaseLedger.vue"),
      },
      {
        path: "/generalledger",
        name: "apps-general-report-listing",
        component: () => import("@/views/apps/reports/GeneralLedgerReport.vue"),
      },
      {
        path: "/reports/generalLedger",
        name: "apps-general-report-list",
        component: () => import("@/views/apps/reports/GeneralLedger.vue"),
      },
      {
        path: "/trialbalance",
        name: "apps-trialbalance-report-listing",
        component: () => import("@/views/apps/reports/TrialBalanceLedger.vue"),
      },
      {
        path: "/incomeexpense",
        name: "apps-incomeexpense-report-listing",
        component: () => import("@/views/apps/reports/IncomeExpense.vue"),
      },
      {
        path: "/assetsliabilities",
        name: "apps-assetsliabilities-report-listing",
        component: () =>
          import("@/views/apps/reports/AssetsLibilityLedger.vue"),
      },
      {
        path: "/clientlisting",
        name: "apps-clients-listing",
        component: () => import("@/views/apps/clients/ClientListing.vue"),
      },
      {
        path: "/clients/client-details/:id",
        name: "apps-clients-detaills",
        props: true,
        component: () => import("@/views/apps/clients/ClientDetails.vue"),
      },
      {
        path: "/clients",
        name: "apps-companies-listing",
        component: () => import("@/views/apps/clients/ClientListing.vue"),
      },
      {
        path: "/clients/contacts",
        name: "apps-clients-contacts",
        component: () => import("@/views/apps/clients/ContactsList.vue"),
      },
      {
        path: "/clients/contact-details/:id",
        name: "apps-client-contacts-details",
        props: true,
        component: () => import("@/views/apps/clients/ContactDetailsView.vue"),
      },
      {
        path: "/company/kmps",
        name: "apps-companies-kmps",
        component: () => import("@/views/apps/kmps/KmpsListing.vue"),
      },
      {
        path: "/company/kmps-details/:id",
        name: "apps-companies-kmps-details",
        props: true,
        component: () => import("@/views/apps/kmps/KmpDetailsView.vue"),
      },
      {
        path: "/salesdistribution/sales-enquiry/",
        name: "apps-sales-enquiry",
        props: true,
        component: () =>
          import("@/views/apps/salesDistribution/SalesEnquiry.vue"),
      },
      // {
      //   path: "/salesdistribution/sales-enquiry/",
      //   name: "apps-sales-enquiry",
      //   props: true,
      //   component: () =>
      //     import("@/components/modals/forms/AddSalesEnquiryModal.vue"),
      // },
      {
        path: "/company/branch-details/:id",
        name: "apps-branch-detaills",
        props: true,
        component: () => import("@/views/apps/branches/BranchesDetails.vue"),
      },
      {
        path: "/salesdistribution/create-sales-enquiry/",
        name: "apps-create-sales-enquiry",
        props: true,
        component: () =>
          import("@/views/apps/salesDistribution/CreateSalesEnquiry.vue"),
      },
      {
        path: "/salesdistribution/create-sales-offer/",
        name: "apps-create-sales-offer",
        props: true,
        component: () =>
          import("@/views/apps/salesDistribution/CreateSalesOffer.vue"),
      },

      {
        path: "/salesdistribution/create-sales-contract/",
        name: "apps-create-sales-contract",
        props: true,
        component: () =>
          import("@/views/apps/salesDistribution/CreateSalesContract.vue"),
      },
      {
        path: "/salesdistribution/create-sales-dispatch-order/",
        name: "apps-create-sales-dispatch-order",
        props: true,
        component: () =>
          import("@/views/apps/salesDistribution/CreateSalesDipatchOrder.vue"),
      },
      {
        path: "/salesdistribution/create-sales-invoice/",
        name: "apps-create-sales-invoice",
        props: true,
        component: () =>
          import("@/views/apps/salesDistribution/CreateSalesInvoice.vue"),
      },
      {
        path: "/salesdistribution/create-credit-debit-note/",
        name: "apps-create-credit-debit-note",
        props: true,
        component: () =>
          import("@/views/apps/salesDistribution/CreateCreditDebitNote.vue"),
      },
      {
        path: "/salesdistribution/create-sales-order/",
        name: "apps-create-sales-order",
        props: true,
        component: () =>
          import("@/views/apps/salesDistribution/CreateSalesOrder.vue"),
      },
      {
        path: "/salesdistribution/sales-order-details/:id",
        name: "apps-display-sales-order",
        props: true,
        component: () =>
          import("@/views/apps/salesDistribution/SalesOrderDetails.vue"),
      },
      // {
      //   path: "/salesdistribution/sales-enquiry/",
      //   name: "apps-sales-enquiry",
      //   props: true,
      //   component: () =>
      //     import("@/views/apps/salesDistribution/CreateSalesEnquiry.vue"),
      // },
      {
        path: "/research/price-collector",
        name: "sales-price-upload-listing",
        component: () => import("@/views/apps/price/PriceCollectorList.vue"),
      },
      {
        path: "/materialmanagement/purchaserequisitionlisting/",
        name: "apps-purchase-rquition-listing",
        props: true,
        component: () =>
          import(
            "@/views/apps/materialManagement/PurchaseRequisitionListing.vue"
          ),
      },
      {
        path: "/materialmanagement/purchaseregisterlisting/",
        name: "apps-purchase-register-listing",
        props: true,
        component: () =>
          import(
            "@/views/apps/materialManagement/PurchaseRegisterList.vue"
          ),
      },
      {
        path: "/materialmanagement/CreatePurchaseRequisition/",
        name: "apps-create-purchase-rquition",
        props: true,
        component: () =>
          import(
            "@/views/apps/materialManagement/CreatePurchaseRequisition.vue"
          ),
      },
      {
        path: "/materialmanagement/PurchaseRequisitionDetails/:id",
        name: "apps-create-purchase-rquisition-details",
        props: true,
        component: () =>
          import(
            "@/views/apps/materialManagement/PurchaseRequisitionDetails.vue"
          ),
      },

      {
        path: "/materialmanagement/planingindentlisting/",
        name: "apps-planing-indent-listing",
        props: true,
        component: () =>
          import("@/views/apps/materialManagement/PlaningIndentListing.vue"),
      },
      {
        path: "/materialmanagement/CreatePlanningIndent/",
        name: "apps-create-planing-indent",
        props: true,
        component: () =>
          import(
            "@/views/apps/materialManagement/CreatePlanningIndent.vue"
          ),
      },
      {
        path: "/materialmanagement/rfqlisting/",
        name: "apps-rfq-listing",
        props: true,
        component: () =>
          import("@/views/apps/materialManagement/RfqListing.vue"),
      },
      {
        path: "/materialmanagement/rfqdetails/:id",
        name: "apps-rfq-details",
        props: true,
        component: () =>
          import("@/views/apps/materialManagement/RfqDetails.vue"),
      },
      {
        path: "/materialmanagement/rfq-offers-listing/:id",
        name: "list_offers_rfq",
        props: true,
        component: () =>
          import("@/views/apps/materialManagement/RfqOffersListing.vue"),
      },

      {
        path: "/materialmanagement/CreateRFQ/",
        name: "apps-create-rfq",
        props: true,
        component: () =>
          import("@/views/apps/materialManagement/CreateRFQ.vue"),
      },
      {
        path: "/materialmanagement/purchaseContractlisting/",
        name: "apps-purchase-contract-listing",
        props: true,
        component: () =>
          import("@/views/apps/materialManagement/PurchaseContractListing.vue"),
      },
      {
        path: "/materialmanagement/CreatePurchaseContract/:id",
        name: "apps-create-purchase-contract",
        props: true,
        component: () =>
          import("@/views/apps/materialManagement/CreatePurchaseContract.vue"),
      },
      {
        path: "/materialmanagement/PurchaseOrderListing/",
        name: "apps-purchase-order-listing",
        props: true,
        component: () =>
          import("@/views/apps/materialManagement/PurchaseOrderListing.vue"),
      },
      {
        path: "/materialmanagement/CreatePurchaseOrder/",
        name: "apps-create-purchase-order",
        props: true,
        component: () =>
          import("@/views/apps/materialManagement/CreatePurchaseOrder.vue"),
      },
      {
        path: "/materialmanagement/PurchaseOrderDetails/:id",
        name: "purchase-order-details",
        props: true,
        component: () =>
          import("@/views/apps/materialManagement/PurchaseOrderDetails.vue"),
      },

      {
        path: "/materialmanagement/purchasedispatchorder/",
        name: "apps-purchase-dispatch-order-listing",
        props: true,
        component: () =>
          import(
            "@/views/apps/materialManagement/PurchaseDispatchOrderListing.vue"
          ),
      },
      {
        path: "/materialmanagement/CreateDispatchOrder/",
        name: "apps-create-purchase-dispatch-order",
        props: true,
        component: () =>
          import(
            "@/views/apps/materialManagement/CreateDispatchOrder.vue"
          ),
      },
      {
        path: "/materialmanagement/goodsreceiptnotelisting/",
        name: "apps-goods-reciept-note-listing",
        props: true,
        component: () =>
          import(
            "@/views/apps/materialManagement/GoodsRecieptNotetListing.vue"
          ),
      },
      {
        path: "/materialmanagement/CreateGoodsRecieptNote/",
        name: "apps-create-goods-reciept-note",
        props: true,
        component: () =>
          import(
            "@/views/apps/materialManagement/CreateGoodsRecieptNote.vue"
          ),
      },
      {
        path: "/materialmanagement/purchaseinvoicelisting/",
        name: "apps-purchase-invoice-listing",
        props: true,
        component: () =>
          import("@/views/apps/materialManagement/PurchaseInvoiceListing.vue"),
      },
      {
        path: "/materialmanagement/createpurchaseinvoice/",
        name: "apps-create-purchase-invoice",
        props: true,
        component: () =>
          import("@/views/apps/materialManagement/CreatePurchaseInvoice.vue"),
      },

      {
        path: "/reports/stockregisterlisting/",
        name: "apps-stock-register-listing",
        props: true,
        component: () =>
          import("@/views/apps/materialManagement/StockRegisterListing.vue"),
      },
      {
        path: "/materialmanagement/PurchaseReturnNoteListing/",
        name: "apps-purchase-return-listing",
        props: true,
        component: () =>
          import(
            "@/views/apps/materialManagement/PurchaseReturnNoteListing.vue"
          ),
      },
      {
        path: "/materialmanagement/JobWorkDeliveryChallanListing/",
        name: "apps-jobwork-delivery-challan-listing",
        props: true,
        component: () =>
          import(
            "@/views/apps/materialManagement/JobWorkDeliveryChallanListing.vue"
          ),
      },
      {
        path: "/materialmanagement/JobworkProcessOrderListing/",
        name: "apps-jobwork-process-order-listing",
        props: true,
        component: () =>
          import(
            "@/views/apps/materialManagement/JobworkProcessOrderListing.vue"
          ),
      },
      {
        path: "/materialmanagement/JobworkReceiptNoteListing/",
        name: "apps-jobwork-receipt-note-listing",
        props: true,
        component: () =>
          import(
            "@/views/apps/materialManagement/JobworkReceiptNoteListing.vue"
          ),
      },
      {
        path: "/materialmanagement/JobworkInvoiceListing/",
        name: "apps-jobwork-invoice-listing",
        props: true,
        component: () =>
          import("@/views/apps/materialManagement/JobworkInvoiceListing.vue"),
      },
      {
        path: "/materialmanagement/StockJournalVoucherListing/",
        name: "apps-stock-journal-voucher-listing",
        props: true,
        component: () =>
          import(
            "@/views/apps/materialManagement/StockJournalVoucherListing.vue"
          ),
      },
      {
        path: "/materialmanagement/GoodsIssueSlipListing/",
        name: "apps-goods-issue-slip-listing",
        props: true,
        component: () =>
          import("@/views/apps/materialManagement/GoodsIssueSlipListing.vue"),
      },
      {
        path: "/materialmanagement/GoodsDepositSlipListing/",
        name: "apps-goods-deposit-slip-listing",
        props: true,
        component: () =>
          import("@/views/apps/materialManagement/GoodsDepositSlipListing.vue"),
      },
      {
        path: "/salesDistribution/SalesOfferListing/",
        name: "apps-sales-offer-listing",
        props: true,
        component: () =>
          import("@/views/apps/salesDistribution/SalesOfferListing.vue"),
      },
      {
        path: "/salesDistribution/SalesOfferList/:id",
        name: "apps-sales-offer-list",
        props: true,
        component: () =>
          import(
            "@/views/apps/salesDistribution/SalesOfferList.vue"
          ),
      },
      {
        path: "/salesDistribution/SalesOfferContractList/:id",
        name: "apps-sales-offer-Contract-list",
        props: true,
        component: () =>
          import(
            "@/views/apps/salesDistribution/SalesOfferContractList.vue"
          ),
      },
      {
        path: "/salesDistribution/SalesContractListing/",
        name: "apps-sales-contract-listing",
        props: true,
        component: () =>
          import("@/views/apps/salesDistribution/SalesContractListing.vue"),
      },
      {
        path: "/salesDistribution/SalesOrderListing/",
        name: "apps-sales-order-listing",
        props: true,
        component: () =>
          import("@/views/apps/salesDistribution/SalesOrderListing.vue"),
      },
      {
        path: "/salesDistribution/SalesDispatchOrderListing/",
        name: "apps-sales-dispatch-order-listing",
        props: true,
        component: () =>
          import(
            "@/views/apps/salesDistribution/SalesDispatchOrderListing.vue"
          ),
      },
      // {
      //   path: "/salesDistribution/PerformaInvoiceListing/",
      //   name: "apps-sales-performa-invoice-listing",
      //   props: true,
      //   component: () =>
      //     import("@/views/apps/salesDistribution/PerformaInvoiceListing.vue"),
      // },
      {
        path: "/salesDistribution/CreditDebitNoteListing/",
        name: "apps-sales-credit-debit-listing",
        props: true,
        component: () =>
          import("@/views/apps/salesDistribution/CreditDebitNoteListing.vue"),
      },
      {
        path: "/salesDistribution/PerformaInvoice/",
        name: "apps-sales-performa-invoice",
        props: true,
        component: () =>
          import("@/views/apps/salesDistribution/PerformaInvoice.vue"),
      },
      {
        path: "/salesDistribution/SalesInvoiceListing/",
        name: "apps-sales-sales-invoice-listing",
        props: true,
        component: () =>
          import("@/views/apps/salesDistribution/SalesInvoiceListing.vue"),
      },
      {
        path: "/salesDistribution/SalesRegisterListing/",
        name: "apps-sales-sales-register-listing",
        props: true,
        component: () =>
          import(
            "@/views/apps/salesDistribution/SalesRegisterList.vue"
          ),
      },
      {
        path: "/materialmanagement/invoice/",
        name: "apps-invoice",
        props: true,
        component: () => import("@/views/apps/materialManagement/invoice.vue"),
        meta: {
          hideNavbar: true,
        },
      },
      {
        path: "/materialmanagement/purchaseledgerReport/",
        name: "apps-purchase-ledger-report",
        props: true,
        component: () =>
          import("@/views/apps/reports/PurchaseLedgerReport.vue"),
      },
      {
        path: "/materialmanagement/SalesLedgerReport/",
        name: "apps-sales-ledger-report",
        props: true,
        component: () => import("@/views/apps/reports/SalesLedgerReport.vue"),
      },
      {
        path: "/materialmanagement/DebitNoteReport/",
        name: "apps-debit-note-report",
        props: true,
        component: () => import("@/views/apps/reports/DebitNoteReport.vue"),
      },
      {
        path: "/materialmanagement/PaymentReceivablesReport/",
        name: "apps-payment-receivables-report",
        props: true,
        component: () =>
          import("@/views/apps/reports/PaymentReceivablesReport.vue"),
      },
      {
        path: "/materialmanagement/AmountPayablesReport/",
        name: "apps-amount-payable-report",
        props: true,
        component: () =>
          import("@/views/apps/reports/AmountPayablesReport.vue"),
      },
      {
        path: "/materialmanagement/GodownReport/",
        name: "apps-godown-report",
        props: true,
        component: () => import("@/views/apps/reports/GodownReport.vue"),
      },

      {
        path: "/logisticsmanagement/TransportationNoteList/",
        name: "apps-transportation-note-listing",
        props: true,
        component: () => import("@/views/apps/logisticsManagement/TransportationNoteList.vue"),
      },

      {
        path: "/logisticsmanagement/CreateTransportationNote/",
        name: "apps-create-transportation-note",
        props: true,
        component: () => import("@/views/apps/logisticsManagement/CreateTransportationNote.vue"),
      },
      {
        path: "/employeeAdd",
        name: "apps-employee-add",
        component: () => import("@/views/apps/hrms/employeeAdd.vue"),
      },
      {
        path: "/employeeList",
        name: "apps-employee-list",
        component: () => import("@/views/apps/hrms/employeeList.vue"),
      },
      // {
      //   path: "/purchaseledgerReport",
      //   name: "apps-purchase-ledger-report",
      //   component: () => import("@/views/apps/reports/PurchaseLedgerReport.vue"),
      // },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/purchase-report/",
    name: "apps-orders-status-report-listing",
    component: () => import("@/views/apps/reports/PoStaticTemplate.vue"),
  },
  {
    path: "/sales-report/",
    name: "apps-so-orders-status-report-listing",
    component: () => import("@/views/apps/reports/SoStaticTemplate.vue"),
  },
  {
    path: "/invoice",
    name: "invoice",
    component: () => import("@/views/apps/materialManagement/invoice.vue"),
  },
  {
    path: "/sjinvoice/:id",
    name: "sjinvoice",
    props: true,
    component: () =>
      import("@/views/apps/materialManagement/stockJournalVoucherInvoice.vue"),
  },
  {
    path: "/prInvoice/:id",
    name: "prInvoice",
    props: true,
    component: () => import("@/views/apps/materialManagement/prInvoice.vue"),
  },
  {
    path: "/purchaseledgerinvoice",
    name: "apps-purchase-report-invoice",
    component: () => import("@/views/apps/reports/PurchaseLedgerInvoice.vue"),
  },
  {
    path: "/SalesLedgerInvoice",
    name: "apps-sales-ledger-invoice",
    component: () => import("@/views/apps/reports/SalesLedgerInvoice.vue"),
  },
  {
    path: "/DebitNote",
    name: "apps-debit-note",
    component: () => import("@/views/apps/reports/DebitNote.vue"),
  },
  {
    path: "/PaymentReceivables",
    name: "apps-payment-recivables",
    component: () => import("@/views/apps/reports/PaymentReceivables.vue"),
  },
  {
    path: "/AmountReceivables",
    name: "apps-amount-recivables",
    component: () => import("@/views/apps/reports/AmountReceivables.vue"),
  },

  {
    path: "/piInvoice/:id",
    name: "piInvoice",
    props: true,
    component: () =>
      import("@/views/apps/materialManagement/purchaseIndentInvoice.vue"),
  },
  {
    path: "/rqInvoice/:id",
    name: "rqInvoice",
    props: true,
    component: () => import("@/views/apps/materialManagement/rqInvoice.vue"),
  },

  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/under-construction",
    name: "under-construction",
    component: () => import("@/views/crafted/authentication/devlopment.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.CUST_VERIFY_AUTH, {
    api_token: JwtService.getToken(),
  });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
