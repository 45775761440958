import ApiService from "@/core/services/CustomeFiApiService";
import JwtService from "@/core/services/JwtService";
// import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { ActionsFi, MutationsFi } from "@/store/enums/StoreFiEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class CompanyFiModule extends VuexModule {
  @Action
  [ActionsFi.CUST_GET_JOURNAL_ENTRIES](param) {
    ApiService.setHeader();
    // return ApiService.post("fa_journal_entries/list", param)
    return ApiService.post("fa_journal/list", param)

      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_ACCOUNT_MASTER_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("fa_master/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_JOURNAL_TYPES_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("fa_journal_types/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_TRANSACTION_NO](param) {
    ApiService.setHeader();
    return ApiService.post("fa_journal_entries/get_transaction_no", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_ADD_JOURNAL_ENTRY](param) {
    ApiService.setHeader();
    return ApiService.post("fa_journal/create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_SALES_REPORT](param) {
    ApiService.setHeader();
    console.log("in action");
    return ApiService.post("fa_reports/sales_ledger_report", param)
      .then(({ data }) => {
        console.log(data);
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        console.log(response);
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PURCHASE_REPORT](param) {
    ApiService.setHeader();
    console.log("in action");
    return ApiService.post("fa_reports/purchase_ledger_report", param)
      .then(({ data }) => {
        console.log(data);
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        console.log(response);
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_TRIAL_BALANCE_REPORT](param) {
    ApiService.setHeader();
    console.log("in action");
    return ApiService.post("fa_reports/trial_balance_unadjusted", param)
      .then(({ data }) => {
        console.log(data);
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        console.log(response);
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_INCOME_EXPENSE_REPORT](param) {
    ApiService.setHeader();
    console.log("in action");
    return ApiService.post("fa_reports/income_expenditure", param)
      .then(({ data }) => {
        console.log(data);
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        console.log(response);
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_ASSETS_LIABILITIES_REPORT](param) {
    ApiService.setHeader();
    console.log("in action");
    return ApiService.post("fa_reports/assets_liabilities", param)
      .then(({ data }) => {
        console.log(data);
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        console.log(response);
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PAYMENT_TERMS_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("sd_common/payment_terms", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_MM_PAYMENT_TERMS_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("mm_common/payment_terms", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_INCOTERMS_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("sd_common/incoterms", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_TRANSPORT_MODE_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("logistics_management/transport_modes", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_SERVICE_COMPANY_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("logistics_management/service_companies", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PNDING_PC_BY_CLIENT_ID](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_contract/pending_pc", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PNDING_PO_BY_CLIENT_ID](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_order/pending_po", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_WAREHOUSE_LIST_BY_USER](param) {
    ApiService.setHeader();
    return ApiService.post("users/warehouse_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_ACCOUNT_EXECUTIVE_LIST_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("users/account_executives", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_DELIVERY_TERMS_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("sd_common/delivery_terms", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_INQUIRY_TYPES_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("sd_common/inquiry_types", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_MODE_INQUIRY](param) {
    ApiService.setHeader();
    return ApiService.post("sd_common/inquiry_modes", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_STOCK_JOURNAL_VOUCHAR_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_stock_journal_vouchers/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_UOM](param) {
    ApiService.setHeader();
    return ApiService.post("sd_common/uom", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_EMPLOYEE_ADD](param) {
    ApiService.setHeader();
    return ApiService.post("hrms/create_employee", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PURCHASE_CONTRACT_CREATE_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_contract/create_page_data", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_TRANSPORT_NOTE_LIST_BY_BUYER](param) {
    ApiService.setHeader();
    return ApiService.post("mm_transport_notes/list_by_si_buyer", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_JOURNAL_TYPE_LIST_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("fa_common/journal_types", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_ACCOUNT_TYPE_LIST_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("fa_common/journal_types", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_OFFER_LIST_BY_INQUIRY_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("fa_common/group_list_by_parent", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_OFFER_LIST_BY_INQUIRY_ID](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_offer/list_by_company", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }


  @Action
  [ActionsFi.CUST_GET_OFFER_DETAILS_BY_INQUIRY_ID](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_inquiry/details_by_id", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }


  @Action
  [ActionsFi.CUST_GET_INWARD_TYPE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_grn/inword_types", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_QUOTATION_REQUEST_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_quotation_request/details", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_QUOTATION_REQUEST_OFFER_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_quotation_request/list_offers_rfq_id", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_SALES_INVOICE_BY_ID_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("sd_crdr_note/client_invoices", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_REASON](param) {
    ApiService.setHeader();
    return ApiService.post("mm_common/stock_movement_reasons", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_REQUISITION_TYPES](param) {
    ApiService.setHeader();
    return ApiService.post("mm_common/stock_product_types", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_GRN_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_grn/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_APPROVE_SALES_CONTRACT](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_contract/approve", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_APPROVE_PURCHASE_CONTRACT](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_contract/approve", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_PURCHASE_CONTRACT_VIEW_BY_ID](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_contract/tc_list_by_pc_id", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_SALES_CONTRACT_VIEW_BY_ID](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_contract/tc_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  
  @Action
  [ActionsFi.CUST_PURCHASE_CONTRACT_TC_UPDATE](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_contract/tc_list_update", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_SALES_CONTRACT_TC_UPDATE](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_contract/tc_list_update", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_APPROVE_SALES_INVOICE](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_invoice/approve", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_PURCHASE_ORDERS_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_order/pending_po", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_PURCHASE_ORDERS_NO_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_order/pending_po", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_APPROVE_SALES_ORDER](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_order/approve", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_APPROVE_PURCHASE_ORDER](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_order/approve", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PURCHASE_REQUISITION_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_request/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PLANING_INDENT_LIST](param) {
    ApiService.setHeader();
    // return ApiService.post("mm_purchase_indent/list", param)
    return ApiService.post("mm_purchase_indent/list_new", param)

      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PURCHASE_PRODUCT_LIST](param) {
    ApiService.setHeader();
    return ApiService.post(
      "mm_purchase_request/pending_purchase_products",
      param
    )
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_SALES_OFFER_BY_COMPANY_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_contract/pending_offers_by_customer", param)
      // return ApiService.post("sd_sales_offer/list_by_company", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_SALES_INVOICE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_invoice/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_SALES_PENDING_DISPATCH_ORDER_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_dispatch/do_by_customer", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PURCHASE_ORDER_BY_ID_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_order/details_by_ids", param)
      .then(({ data }) => {
        debugger
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_SO_BY_CUSTOMER_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_order/pending_so_by_customer", param)
      .then(({ data }) => {
        debugger
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_PURCHASE_ORDER_BY_ID_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_order/details", param)
      .then(({ data }) => {
        debugger
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_TRANSPORT_NO_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_grn/tn_list", param)
      .then(({ data }) => {
        debugger
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_DISPATCH_ORDER_BY_ID_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_grn/add_page_data_list", param)
      .then(({ data }) => {
        debugger
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_SALES_OFFER_LISTING_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_offer/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_SALES_INQUIRY_LISTING_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_inquiry/pending_inquiries_by_customer", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_SALES_INVOICE_BY_ID_LISTING_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_invoice/details_by_id", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_COMPANY_BRANCH_ADDRESS_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("sd_common/company_branches", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_INDENT_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_indent/pending_indent_products", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PC_DETAILS_BY_IDS_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_contract/details_by_ids", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PO_DETAILS_BY_IDS_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_order/details_by_ids", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_PO_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_dispatch_order/pending_pdo", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_CONRACT_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_contract/pending_pc", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_CONRACT_BY_ID_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_contract/details_by_ids", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_QUOTAION_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_quotation_request/pending_qr", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_RFQ_OFFER_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_quotation_request/list_offers_rfq_id", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_QUOTATION_REQUEST_BY_CLIENT_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_quotation_request/pending_qr_by_client", param)
      .then(({ data }) => {
        debugger
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_PURCHASE_CONTRACT_BY_CLIENT_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_contract/pending_pc", param)
      .then(({ data }) => {
        debugger
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_MATERIAL_CONDITION_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("mm_common/material_conditions", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_STOCK_REGISTER_LISTING_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("mm_stock_entry/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_SALES_PROFORMA_LISTING_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("sd_proforma_invoice/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_STOCK_REGISTER_LISTING](param) {
    ApiService.setHeader();
    return ApiService.post("common_stock/report", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PURCHASE_REGISTER_LISTING](param) {
    ApiService.setHeader();
    return ApiService.post("mm_reports/purchase_register", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_SALES_INQUIRY_LISTING_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_inquiry/list_new", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_EMPLOYEE_LISTING_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("hrms/list_employee", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_CREDIT_DEBIT_NOTE_LISTING_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("sd_crdr_note/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_CREDIT_DEBIT_REASON_LISTING_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("sd_crdr_note/reason_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PURCHASE_DISPATCH_ORDER_LISTING_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_dispatch_order/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_SALES_DISPATCH_ORDER_LIST_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_dispatch/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_SALES_ORDER_LISTING_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_order/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_OPEN_PURCHES_REQUEST_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_request/open_pr", param)
      .then(({ data }) => {
        if (data != "") {
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_SALES_CONTRACTS_BY_ID_LISTING_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_contract/details_by_ids", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_SALES_CONTRACT_LISTING_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_contract/pending_sales_contracts", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_SALES_CONTRACT_LISTING_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_contract/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PRODUCT_NON_SALEABLE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_stock_products/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_REQUEST_FOR_QUOTATION_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_quotation_request/list_new", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PURCHASE_CONTRACT_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_contract/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PURCHASE_ORDER_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_order/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_PURCHASE_INVOICE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_invoice/list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_SJ_INVOICE_DETAILS](param) {
    ApiService.setHeader();
    return ApiService.post("mm_stock_journal_vouchers/details", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  @Action
  [ActionsFi.CUST_GET_CURRENCY](param) {
    ApiService.setHeader();
    return ApiService.post("mm_common/currencies", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_SALES_LEDGER_REPORT](param) {
    ApiService.setHeader();
    return ApiService.post("sd_reports/sales_payment_report", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_PURCHASE_LEDGER_REPORT](param) {
    ApiService.setHeader();
    return ApiService.post("mm_reports/purchase_payments", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_PAYMENT_RECEIVABLE_REPORT](param) {
    ApiService.setHeader();
    return ApiService.post("sd_reports/account_receivable_report", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_AMOUNT_PAYABLE_REPORT](param) {
    ApiService.setHeader();
    return ApiService.post("mm_reports/account_payable_report", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.FA_ACCOUNT_PAYABLE_REPORT](param) {
    ApiService.setHeader();
    return ApiService.post("fa_reports/account_payable", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.FA_ACCOUNT_RECEIVABLE_REPORT](param) {
    ApiService.setHeader();
    return ApiService.post("fa_reports/account_receivable", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.FA_DOWNLOAD_ACC_PAYABLE_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("fa_reports/account_payable_download", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.FA_DOWNLOAD_ACC_RECEIVABLE_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("fa_reports/account_receivable_download", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.DOWNLOAD_STOCK_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("common_stock/report_download", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GENERAL_LEDGER_REPORT](param) {
    ApiService.setHeader();
    return ApiService.post("fa_reports/general_ledger", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  
  @Action
  [ActionsFi.CUST_CREATE_PURCHASE_REQUISITION](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_request/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_PDF_REQUEST](param) {
    ApiService.setHeader();
    return ApiService.post("pdf_request", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PURCHASE_REQUISITION_DETAILS](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_request/view", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_UPDATE_PURCHASE_REQUISITION_DETAILS](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_request/update", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_DELETE_PURCHASE_REQUISITION_PRODUCT](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_request/delete", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_WAREHOUSE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_common/warehouse", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_PURCHASE_REQUISITION_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_request/open_pr", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_MAINTAIN_BY_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_common/maintain_by", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  
  @Action
  [ActionsFi.CUST_GET_PURCHASE_QUOTATION_DETAILS](param) {
    ApiService.setHeader();
    return ApiService.post("mm_quotation_request/details", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PURCHASE_QUOTATION_LIST_DETAILS](param) {
    ApiService.setHeader();
    return ApiService.post("mm_quotation_request/list_new", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_CREATE_UPDATE_PURCHASE_QUOTATION_OFFER](param) {
    ApiService.setHeader();
    return ApiService.post("mm_quotation_request/create_offer", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  
  @Action
  [ActionsFi.CUST_CREATE_PURCHASE_REQUEST_QUOTATION](param) {
    ApiService.setHeader();
    return ApiService.post("mm_quotation_request/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_QUOTATION_PRODUCT_CHARGE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_quotation_request/product_charge_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PRODUCT_DESCRIPTION](param) {
    ApiService.setHeader();
    return ApiService.post("mm_quotation_request/product_desc", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PURCHASE_QUOTATION_OFFER_DETAILS](param) {
    ApiService.setHeader();
    return ApiService.post("mm_quotation_request/view_offers_rfq", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_OFFERS_PRODUCT_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_quotation_request/offers_product_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  
  @Action
  [ActionsFi.CUST_GET_OFFER_CPMARISION](param) {
    ApiService.setHeader();
    return ApiService.post("mm_quotation_request/offers_comparison", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_CREATE_PURCHASE_CONTRACT](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_contract/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  @Action
  [ActionsFi.CUST_CREATE_PURCHASE_ORDER](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_order/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_CREATE_PURCHASE_DISPATCH_ORDER](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_dispatch_order/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_LOGISTIC_MANAGEMENT_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("logistics_management/list_tn", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  @Action
  [ActionsFi.CUST_GET_LOGISTIC_MANAGEMENT_DOC_TYPE](param) {
    ApiService.setHeader();
    return ApiService.post("logistics_management/doc_types", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_CREATE_TRANSPORTATION_NOTE](param) {
    ApiService.setHeader();
    return ApiService.post("logistics_management/create_tn", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_TN_PENDINT_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("logistics_management/tn_pending_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_TN_PRODUCTS](param) {
    ApiService.setHeader();
    return ApiService.post("logistics_management/tn_products", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_CREATE_GRN](param) {
    ApiService.setHeader();
    return ApiService.post("mm_grn/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_GRN_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("mm_grn/pending_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_GRN_DETAILS](param) {
    ApiService.setHeader();
    return ApiService.post("mm_grn/details", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_CREATE_PURCHASE_INVOICE](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_invoice/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PURCHASE_INVOICE_CHARGES](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_invoice/charges", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_APPROVE_GRN](param) {
    ApiService.setHeader();
    return ApiService.post("mm_grn/approve", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_APPROVE_INVOICE](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_invoice/approve", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_APPROVE_DISPATCH_ORFER](param) {
    ApiService.setHeader();
    return ApiService.post("mm_purchase_dispatch_order/approve", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_CREATE_SALSE_ENQUIRY](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_inquiry/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_LIST_OFFERS_BY_ENQUIRY](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_offer/list_offers_by_enquiry", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_CREATE_SALES_OFFER](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_offer/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_CREATE_SALES_CONTRACT](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_contract/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_OFFER_LIST_CONTRACTS](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_offer/list_contracts", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_CREATE_SALES_ORDER](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_order/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_SALSE_ORDER_DETAILS](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_order/details", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PRODUCT_STOCK_DETAILS](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_order/product_stock_details", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_STOCK_ALLOCATION_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_order/stock_allocation_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_STOCK_ALLOCATION_SAVE](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_order/stock_allocation_save", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_SO](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_order/pending_so_by_ids", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_CREATE_SALES_DISPATCH_ORDER](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_dispatch/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PROFORMA_PENDING_SO](param) {
    ApiService.setHeader();
    return ApiService.post("sd_proforma_invoice/so_by_customer", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PROFORMA_SO_PRODUCT](param) {
    ApiService.setHeader();
    return ApiService.post("sd_proforma_invoice/so_products", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_CREATE_PROFORMA_INVOICE](param) {
    ApiService.setHeader();
    return ApiService.post("sd_proforma_invoice/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_TN_LIST_BY_BYER](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_invoice/tn_list_by_buyer", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_PENDING_DO](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_invoice/pending_do", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_SALES_INVOICE_CHARGE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_invoice/charges", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_CREATE_SALES_INVOICE](param) {
    ApiService.setHeader();
    return ApiService.post("sd_sales_invoice/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_CR_DR_INVOICE_DETAILS](param) {
    ApiService.setHeader();
    return ApiService.post("sd_crdr_note/invoice_details", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUUST_CREATE_CR_DR_NOTE](param) {
    ApiService.setHeader();
    return ApiService.post("sd_crdr_note/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_SALES_REGISTER_LISTING](param) {
    ApiService.setHeader();
    return ApiService.post("mm_reports/sales_register", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_FA_SEARCH_LEDGER](param) {
    ApiService.setHeader();
    return ApiService.post("fa_common/search_ledger", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_FA_APPROVE_JOURNAL](param) {
    ApiService.setHeader();
    return ApiService.post("fa_journal/approve", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_LEDGER_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("fa_ledgers/list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_JOURNAL_ENTRY_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("fa_journal/view", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [ActionsFi.CUST_GET_GENERAL_LEDGER_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("fa_reports/general_ledger", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

}


