enum ActionsFi {
  // action types
  CUST_GET_JOURNAL_ENTRIES = "custJournalEntries",
  CUST_GET_ACCOUNT_MASTER_LIST = "custAccountMasterList",
  CUST_GET_JOURNAL_TYPES_LIST = "custJournalTypesList",
  CUST_GET_TRANSACTION_NO = "custTransactionNo",
  CUST_ADD_JOURNAL_ENTRY = "custAddJournalEntry",
  CUST_GET_SALES_REPORT = "custGetSalesReport",
  CUST_GET_PURCHASE_REPORT = "custGetPurchaseReport",
  CUST_GET_TRIAL_BALANCE_REPORT = "custGetTrialBalanceReport",
  CUST_GET_INCOME_EXPENSE_REPORT = "custGetIncomeExpenseReport",
  CUST_GET_ASSETS_LIABILITIES_REPORT = "custGetAssetsLiabilitiesReport",
  CUST_GET_DELIVERY_TERMS_DATA = "custGetDeliveryTermsData",
  CUST_GET_PAYMENT_TERMS_DATA = "custGetPaymentTermsData",
  CUST_GET_INCOTERMS_DATA = "custGetIncotermsData",
  CUST_GET_INQUIRY_TYPES_DATA = "custGetInquiryTypesData",
  CUST_GET_MODE_INQUIRY = "custGetInquiryModesData",
  CUST_GET_UOM = "custGetUOMData",
  CUST_GET_INWARD_TYPE_LIST = "custInwardTypeList",
  CUST_GET_SALES_INVOICE_BY_ID_LIST = "custInvoiceByIdList",
  CUST_GET_REASON = "custGetReasonData",
  CUST_GET_REQUEST_FOR_QUOTATION_LIST = "custRequestForQuotationListData",
  CUST_GET_STOCK_JOURNAL_VOUCHAR_LIST = "custStockJournalVoucherListData",
  CUST_GET_PURCHASE_CONTRACT_LIST = "custGetPurchaseContractListData",
  CUST_GET_SJ_INVOICE_DETAILS = "custGetSJInvoiceData",
  CUST_GET_QUOTATION_REQUEST_LIST = "custGetQuotationRequestList",
  CUST_GET_ACCOUNT_EXECUTIVE_LIST_DATA = "custGetAccountExecutiveListData",
  CUST_GET_QUOTATION_REQUEST_OFFER_LIST = "custGetQuotationRequestOfferList",
  CUST_GET_OFFER_LIST_BY_INQUIRY_DATA = "CustGetOfferListBytInquiryData",
  CUST_GET_JOURNAL_TYPE_LIST_DATA = "CustGetJouranTypeListData",
  CUST_GET_ACCOUNT_TYPE_LIST_DATA = "CustGetAccountTypeListData",
  CUST_GET_OFFER_DETAILS_BY_INQUIRY_ID = "CustGetOfferDetailsByInquiryID",
  CUST_GET_OFFER_LIST_BY_INQUIRY_ID = "CustGetOfferListBytInquiryByInquiryID",
  CUST_GET_TRANSPORT_NOTE_LIST_BY_BUYER = "CustGetTransportNoteListByBuyer",
  CUST_GET_WAREHOUSE_LIST_BY_USER = "CustGetWarehouseListByUser",
  CUST_GET_MM_PAYMENT_TERMS_DATA = "custGetMMPaymentTermsData",
  CUST_GET_PURCHASE_CONTRACT_CREATE_DATA = "custGetPurchaseContractCreateData",
  CUST_GET_PNDING_PC_BY_CLIENT_ID = "custGetPendingPCByClientId",
  CUST_GET_PNDING_PO_BY_CLIENT_ID = "custGetPendingPOByClientId",
  CUST_GET_TRANSPORT_MODE_DATA = "custGetTransportModeData",
  CUST_GET_SERVICE_COMPANY_LIST = "custGetServiceCompanyList",
  CUST_PURCHASE_INVOICE_LIST = "custPurchaseInvoiceList",
  CUST_EMPLOYEE_ADD = "custEmployeeAdd",


  CUST_GET_REQUISITION_TYPES = "CUST_GET_REQUISITION_TYPES",
  CUST_GET_GRN_LIST = "custGetGrnList",
  CUST_GET_CURRENCY = "CUST_GET_CURRENCY",
  CUST_GET_PURCHASE_REQUISITION_LIST = "custGetPurchaseRequisitionList",
  CUST_GET_PLANING_INDENT_LIST = "custGetPlanningIndentData",
  CUST_GET_PURCHASE_PRODUCT_LIST = "custGetPurchaseProductList",
  CUST_GET_SALES_OFFER_BY_COMPANY_LIST = "custGetSalesOfferByCompanyList",
  CUST_GET_PURCHASE_ORDER_BY_ID_LIST = "custGetPurchaseOrderByIDList",
  CUST_GET_PENDING_PURCHASE_ORDER_BY_ID_LIST = "custGetPendingPurchaseOrderByIDList",
  CUST_GET_PENDING_DISPATCH_ORDER_BY_ID_LIST = "custGetPendingDispatchOrderByIDList",
  CUST_GET_PENDING_INDENT_LIST = "custGetPendingIndentProductList",
  CUST_GET_PENDING_CONRACT_LIST = "custGetPendingContractProductList",
  CUST_GET_PENDING_QUOTAION_LIST = "custGetPendingQuotationProductList",
  CUST_GET_COMPANY_BRANCH_ADDRESS_LIST = "custGetComapnyBranchAddressList",
  CUST_APPROVE_SALES_CONTRACT = "custApproveSalesContract",
  CUST_APPROVE_SALES_INVOICE = "custApproveSalesInvoice",
  CUST_GET_PENDING_PURCHASE_ORDERS_LIST = "custGetPendingPurchaseOrderList",
  CUST_GET_SALES_PENDING_DISPATCH_ORDER_LIST = "custGetSalesPendingDispatchOrderList",
  CUST_APPROVE_SALES_ORDER = "custApproveSalesOrder",
  CUST_GET_SALES_INVOICE_LIST = "custGetSalesInvoiceList",
  CUST_GET_OPEN_PURCHES_REQUEST_LIST = "custOpenPurchesRequestList",
  CUST_GET_PENDING_QUOTATION_REQUEST_BY_CLIENT_LIST = "custGetPendingQuotationRequestByClientList",
  CUST_GET_PENDING_PURCHASE_CONTRACT_BY_CLIENT_LIST = "custGetPendingPurchaseContractByClientList",
  CUST_GET_PENDING_RFQ_OFFER_LIST = "custGetPendingRFQOfferList",
  CUST_GET_PENDING_CONRACT_BY_ID_LIST = "custGetPendingContractByIDList",
  CUST_GET_TRANSPORT_NO_LIST = "custGetTransportNoList",
  CUST_GET_PENDING_PURCHASE_ORDERS_NO_LIST = "custGetPendingPurchaseOrderNoList",
  CUST_GET_PENDING_SO_BY_CUSTOMER_LIST = "custGetPendingSoByCustomerList",
  CUST_APPROVE_PURCHASE_CONTRACT = "custApprovePurchaseContract",
  CUST_GET_PC_DETAILS_BY_IDS_DATA = "custGetPcDetailsByIDSData",
  CUST_GET_PO_DETAILS_BY_IDS_DATA = "custGetPoDetailsByIDSData",
  CUST_APPROVE_PURCHASE_ORDER = "custApprovePurchaseOrder",
  CUST_GET_PENDING_PO_LIST = "custGetPendingPoList",
  CUST_PURCHASE_CONTRACT_VIEW_BY_ID = "custPurchaseContractListById",
  CUST_PURCHASE_CONTRACT_TC_UPDATE = "custPurchaseContractTcUpdate",


  CUST_GET_MATERIAL_CONDITION_DATA = "custGetMaterialConditionData",
  CUST_GET_STOCK_REGISTER_LISTING_DATA = "custGetStockRegisterListingData",
  CUST_GET_STOCK_REGISTER_LISTING = "custGetStockRegisterListing",
  CUST_GET_SALES_ORDER_LISTING_DATA = "custGetSalesOrderListingData",
  CUST_GET_SALES_CONTRACTS_BY_ID_LISTING_DATA = "custGetSalesContractByIDListingData",
  CUST_GET_PENDING_SALES_CONTRACT_LISTING_DATA = "custGetPendingSalesContractListingData",
  CUST_GET_SALES_CONTRACT_LISTING_DATA = "custGetSalesContractListingData",
  CUST_GET_SALES_OFFER_LISTING_DATA = "custGetSalesOfferListingData",
  CUST_GET_SALES_INVOICE_BY_ID_LISTING_DATA = "custGetSalesInvoiceByIDListingData",
  CUST_GET_PRODUCT_NON_SALEABLE_LIST = "custGetProductListNonSalableData",
  CUST_GET_PURCHASE_ORDER_LIST = "custPurchaseOrderList",
  CUST_GET_SALES_DISPATCH_ORDER_LIST_DATA = "custGetSalesDispatchOrderListData",
  CUST_GET_CREDIT_DEBIT_NOTE_LISTING_DATA = "custGetCreditDebitNoteListingData",
  CUST_GET_CREDIT_DEBIT_REASON_LISTING_DATA= "custGetCreditDebitNoteReasonList",
  CUST_GET_PURCHASE_DISPATCH_ORDER_LISTING_DATA = "custGetPurchaseDispatchOrderData",
  CUST_GET_PENDING_SALES_INQUIRY_LISTING_DATA = "custGetPendingSalesInquiryListingData",
  CUST_GET_SALES_INQUIRY_LISTING_DATA = "custGetSalesInquiryListingData",

  CUST_GET_EMPLOYEE_LISTING_DATA = "custGetEmployeeListingData",


  CUST_SALES_LEDGER_REPORT = "custSalesLedgerReport",
  CUST_PURCHASE_LEDGER_REPORT = "custPurchaseLedgerReport",

  CUST_PAYMENT_RECEIVABLE_REPORT = "custPaymentRecReport",
  CUST_AMOUNT_PAYABLE_REPORT = "custAmountPayReport",

  CUST_GENERAL_LEDGER_REPORT = "custGeneralLedgerReport",
  CUST_GET_PURCHASE_REQUISITION_DETAILS = 'custGetPurchaseRequisitionDetails',
  CUST_UPDATE_PURCHASE_REQUISITION_DETAILS = 'custUpdatePurchaseRequisitionDetails',
  CUST_DELETE_PURCHASE_REQUISITION_PRODUCT = 'custDeletePurchaseRequisitionProduct',
  CUST_CREATE_PURCHASE_REQUISITION = 'custCreaePurchaseRequisition',


  CUST_GET_PURCHASE_QUOTATION_DETAILS = 'custGetPurchaseQuotationDetails',
  CUST_GET_PURCHASE_QUOTATION_LIST_DETAILS = 'custGetPurchaseQuotationListDetails',

  CUST_GET_WAREHOUSE_LIST = "custGetWarehouseList",
  CUST_GET_PENDING_PURCHASE_REQUISITION_LIST = "custGetPendingPurchaseRequisitionList",
  CUST_GET_MAINTAIN_BY_LIST = "custGetMaintainByList",

  CUST_CREATE_PURCHASE_REQUEST_QUOTATION = 'custCreatePurchaseRequestQuotation',
  CUST_CREATE_UPDATE_PURCHASE_QUOTATION_OFFER = 'custCreateUpdatePurchaseQuotationOffer',
  CUST_GET_QUOTATION_PRODUCT_CHARGE_LIST = 'custGetQuotationProductChargeList',
  CUST_GET_PRODUCT_DESCRIPTION = 'custGetProductDescription',
  CUST_GET_PURCHASE_QUOTATION_OFFER_DETAILS = 'custGetPurchaseQuotationOfferDetails',
 
  CUST_GET_OFFERS_PRODUCT_LIST = 'custGetOffersProductList',
  CUST_GET_OFFER_CPMARISION = 'custGetOfferComparision',
 
  CUST_CREATE_PURCHASE_CONTRACT = 'custCreatePurchaseContract',
  CUST_CREATE_PURCHASE_ORDER = 'custCreatePurchaseOrder',
  CUST_CREATE_PURCHASE_DISPATCH_ORDER = 'custCreatePurchaseDispatchOrder',
  CUST_GET_LOGISTIC_MANAGEMENT_LIST = 'custGetLogisticManagementList',
  CUST_GET_LOGISTIC_MANAGEMENT_DOC_TYPE = 'custGetLogisticManagementDocType',
  CUST_CREATE_TRANSPORTATION_NOTE = 'custCreateTransportationNote',
  CUST_GET_TN_PENDINT_LIST = 'custGetTNPendingList',
  CUST_GET_TN_PRODUCTS = 'custGetTNProducts',
  CUST_CREATE_GRN = 'custCreateGrn',
  CUST_GET_PENDING_GRN_LIST = 'custGetPendingGrnList',
  CUST_GET_GRN_DETAILS = 'custGetGrnProductList',
  CUST_CREATE_PURCHASE_INVOICE = 'custCreatePurchaseInvoice',
  CUST_GET_PURCHASE_INVOICE_CHARGES = 'custGetPurchaseInvoiceCharges',
  CUST_APPROVE_GRN = 'custApproveGrn',
  CUST_APPROVE_INVOICE= 'custApproveInvoice',
  CUST_APPROVE_DISPATCH_ORFER= 'custApproveDispatchOrder',
  CUST_CREATE_SALSE_ENQUIRY = 'custCreateSalseEnquiry',

  CUST_PDF_REQUEST = "custPdfRquest",

  CUST_GET_LIST_OFFERS_BY_ENQUIRY = 'custGetListOfferByenquiry',
  CUST_CREATE_SALES_OFFER = 'custCreateSalesOffer',
  CUST_CREATE_SALES_CONTRACT = 'custCreateSalesContract',
  CUST_GET_OFFER_LIST_CONTRACTS = 'custGetOfferListContract',
  
  CUST_SALES_CONTRACT_VIEW_BY_ID = 'custSalesContractListById',
  CUST_SALES_CONTRACT_TC_UPDATE = 'custSalesContractUpdateById',

  CUST_GET_SALES_PROFORMA_LISTING_DATA = 'custSalesProformaInvoiceList',

  CUST_CREATE_SALES_ORDER = 'custCreateSalesOrder',

  CUST_SALSE_ORDER_DETAILS = 'custSalesOrderDetails',
  CUST_GET_PRODUCT_STOCK_DETAILS = 'custGetProductStockDetails',
  CUST_GET_STOCK_ALLOCATION_LIST = 'custGetStockAllocationList',
  CUST_STOCK_ALLOCATION_SAVE = 'custStockAllocationSave',

  CUST_GET_PENDING_SO = 'custGetPendingSo',
  CUST_CREATE_SALES_DISPATCH_ORDER = 'custCreateSalesDispatchOrder',
  CUST_GET_PROFORMA_PENDING_SO = 'custGetProformaPendingSo',
  CUST_GET_PROFORMA_SO_PRODUCT = 'custGetProformaSoProduct',
  CUST_CREATE_PROFORMA_INVOICE = 'custCreateProformaInvoice',

  CUST_GET_TN_LIST_BY_BYER = 'custGetTNListByBuyer',
  CUST_GET_PENDING_DO = 'custGetPendingDo',
  CUST_SALES_INVOICE_CHARGE_LIST = 'custSalesInvoiceChargeList',
  CUST_CREATE_SALES_INVOICE = 'custCreateSalesInvoice',
  CUST_GET_CR_DR_INVOICE_DETAILS = 'custGetCrDrInvoiceDetails',
  CUUST_CREATE_CR_DR_NOTE = 'custCreateCrDrNote',

  CUST_GET_PURCHASE_REGISTER_LISTING = 'custPurchaseRegisterList',
  CUST_GET_SALES_REGISTER_LISTING = 'custSalesRegisterList',

  FA_ACCOUNT_PAYABLE_REPORT = ' faAccountPayableReport',
  FA_ACCOUNT_RECEIVABLE_REPORT = ' faAccountReceivableReport',
  FA_DOWNLOAD_ACC_PAYABLE_DATA = 'faDowloadAccPayableReport',
  FA_DOWNLOAD_ACC_RECEIVABLE_DATA = 'faDowloadAccReceivableReport',
  DOWNLOAD_STOCK_DATA = 'DownloadStockData',
  CUST_FA_SEARCH_LEDGER = 'custSearchLedger',
  CUST_FA_APPROVE_JOURNAL = 'custFaApproveJournal',
  CUST_GET_LEDGER_LIST = 'custGetLedgerLis',
  CUST_GET_JOURNAL_ENTRY_DATA = 'custGetJournalEntryData',
  CUST_GET_GENERAL_LEDGER_LIST = 'custGetGeneralLedgerList' 
 

}

enum MutationsFi { }
// mutation types

export { ActionsFi, MutationsFi };
