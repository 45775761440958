const FI_USER = [] as any
const FI_USER_DATA = [] as any

/**
 * @description get token form localStorage
 */
export const getToken = (): any | null => {
  return window.localStorage.getItem("FI_USER");
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: any): void => {  
  window.localStorage.setItem( "FI_USER", JSON.stringify(token));
  // window.localStorage.setItem( USER, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem("FI_USER");
};

export const saveUserData = (token: any): void => {  
  window.localStorage.setItem( "FI_USER_DATA", JSON.stringify(token));
  // window.localStorage.setItem( USER, token);
};

export const getUserData = (): any | null => {
  return window.localStorage.getItem("FI_USER_DATA");
};

export default { getToken, saveToken, destroyToken, saveUserData, getUserData };
