enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",

  CUST_GET_USER_PROFILE_LIST = "custUserProfileList",
  CUST_GET_USER_PROFILE_DP = "custUserProfileDropdown",
  CUST_GET_USER_PROFILE_PINCODE_SEARCH = "custUserProfilePincodeSearch",
  CUST_GET_USER_COMPANY_SEARCH_DP = "custUserProfileCompanySearch",
  CUST_GET_USER_PROFILE_GSTIN_SEARCH = "custUserProfileGstSearch",
  CUST_GET_USER_PROFILE_GSTIN_INFO = "custUserProfileGstInfo",

  CUST_GET_USER_PROFILE_IFSC_SEARCH = "custUserProfileIfscSearch",
  CUST_GET_USER_PROFILE_PAN_SEARCH = "custUserProfilePanSearch",
  CUST_BUSINESS_ENTITY_ADD = "custBusinessEntityAdd",
  CUST_BUSINESS_ENTITY_INDIVIDUAL_ADD = "custBusinessEntityIndividualAdd",
  CUST_BUSINESS_ENTITY_INDIVIDUAL_UPDATE = "custBusinessEntityIndividualUpdate",
  CUST_BUSINESS_ENTITY_INDIVIDUAL_LIST = "custBusinessEntityIndividualList",
  DOCUMENT_DP = "DocDropdown",
  DOCUMENT_UPLOAD = "DocumentUpload",
  DOCUMENT_LIST = "DocumentList",
  DOCUMENT_COMPANY_UPLOAD = "DocumentCompanyUpload",
  DOCUMENT_COMPANY_LIST = "DocumentCompanyList",

  CUST_VERIFY_AUTH = "custVerifyAuth",
  CUST_LOGIN = "custLogin",
  CUST_GET_COMPANY_LIST = "custCompList",
  CUST_SEARCH_COMPANY_LIST = "custSearchCompList",
  CUST_GET_BRANCH_LIST = "custBranchList",
  CUST_TRANSPORT_GRN_DP = "custGrnTransportDp",
  CUST_GET_USER_CONTACT_LIST = "custUserContactList",
  CUST_GET_USER_CONTACT_ADD = "custUserContactAdd",
  CUST_GET_USER_CONTACT_UPDATE = "custUserContactUpdate",
  CUST_GET_PLANT_LIST = "custPlantList",
  CUST_GET_USER_KMP_ADD = "custUserProfileKmpAdd",
  CUST_GET_USER_KMP_UPDATE = "custUserProfileKmpUpdate",
  CUST_GET_USER_BANK_LIST = "custUserBankList",
  CUST_ADD_USER_BANK = "custAddUserBank",
  CUST_GET_USER_BRAND_LIST = "custUserBrandList",
  CUST_ADD_USER_BRAND = "custAddUserBrand",
  CUST_GET_WAREHOUSE_LIST = "custWarehouseList",
  CUST_GET_EDIT_BRANCH = "custBranchEdit",
  CUST_GET_KMP_COMP_LIST = "custKmpCompList",
  CUST_GET_KMP_LIST = "custKmpList",
  CUST_ADD_BRANCH = "custAddBranch",
  CUST_UPDATE_BRANCH = "custUpdateBranch",
  CUST_EDIT_BRANCH = "custEditBranch",
  CUST_GET_PINCODE = "custPinCode",
  CUST_ADD_COMPANY = "custAddCompany",
  CUST_GET_COMPANY_PAGE_DATA = "custGetCompanyPageData",
  CUST_EDIT_COMPANY = "custEditCompany",
  CUST_ADD_KMP = "custAddKmp",
  CUST_LINK_KMP = "custLinkKmp",
  CUST_GET_DIN = "custGetDin",
  CUST_GET_DIN_BY_SEARCH = "custDinBySearch",
  KMP_VIEW = "KmpView",
  KMP_CREATE_UPDATE = "KmpCreateUpdate",
  CUST_ADD_UPDATE_ADDRESSES = "custAddAddresses",
  CUST_LIST_ADDRESSES = "custListAddresses",

  CUST_ADD_UOM_LIST = "custUomList",
  CUST_ATTRIBUTE_RANGE_LIST = "custRangeList",
  CUST_CATEGORY_GRADE_MAPPING_LIST = "custCategoryGradeMapping",
  CUST_PRODUCT_ATTRIBUTE_LIST_DISPLAY = "custProductAttributeListDisplay",
  CUST_GET_PRODUCT_PRICE_LIST = "custPriceList",
  CUST_PRODUCT_GRADE_MAPPING_LIST = "custProductGradeMapping",
  CUST_LINK_ATTRIBUTE_RANGE = "custSaveRange",
  CUST_PRODUCT_BRAND = "custProductBrand",

  CUST_PRO_GRADE = "custProGrade",
  CUST_PRO_STANDARD = "custProStandard",
  CUST_ADD_PRO_GRADE = "custAddProGrade",

  CUST_SELLER_BRANCH_LIST = "custSellerBranch",
  CUST_PRODUCT_INCOTERM = "custProductIncoterm",
  CUST_PRODUCT_DELIVERY_TERM = "custProductDeliveryTerm",

  CUST_CAT_GRADE = "custCatGrade",
  CUST_CAT_STANDARD = "custCatStandard",
  CUST_ADD_CAT_GRADE = "custAddCatGrade",

  CUST_GET_PRODUCT_ATTRIBUTE = "custProductAttribute",

  CUST_PRODUCT_ATTRIBUTE_VALUE_LIST = "custProductAttrValue",
  CUST_PRODUCT_MAPPING_ATTRIBUTE = "custProductAttrSave",
  CUST_GET_FORM_CATEGORY = "custFormCategory",
  CUST_GET_Stage_CATEGORY = "custStageCategory",
  CUST_GET_Length_CATEGORY = "custLengthCategory",
  CUST_PRODUCT_UOM = "custProductUom",

  CUST_PRODUCT_CURRENCY = "cusProductCurrency",
  CUST_ADD_PRODUCT_INVENTORY = "custAddProductInventory",

  CUST_GET_PRODUCT_BRAND = "custGetProductBrand",

  CUST_HSN_LIST = "custHsnList",
  CUST_ADD_CATEGORY_DP = "custAddDpCategory",
  CUST_ADD_CATEGORY = "custAddProdct",
  CUST_GET_INDUSTRY_CATEGORY = "custIndustryCategory",
  CUST_GET_SHAPE_CATEGORY = "custShapeCategory",
  CUST_GET_TAGLIST = "",
  CUST_GET_CATEGORY_LIST = "custCategoryList",
  CUST_GET_CATEGORY_NEW_DB_LIST = "custCategoryNewDBList",
  CUST_GET_PARENT_CATEGORY = "custParentCategory",
  CUST_HSN_CATEGORY = "custHsnCategory",
  CUST_GET_PRODUCT_STANDARD_LIST = "custProductStandard",
  CUST_ADD_PRODUCT_STANDARD = "custAddProductStandard",
  CUST_GET_PRODUCT_STANDARD_MASTER_LIST = "custProductStandardMaster",
  CUST_ADD_PRODUCT_GRADE = "custProductGrade",
  CUST_GET_GRADE_LIST = "custGradeList",
  CUST_UPDATE_CATEGORY = "custUpdateCategory",
  CUST_UPDATE_PRODUCT_STANDARD = "custUpdateProductStandard",
  CUST_UPDATE_GRADE = "custUpdateGrade",
  CUST_GET_GRADE_STANDARD = "custGradeStandard",
  CUST_LINK_PRODUCT_STANDARD_CAT = "custLinkProd",
  CUST_ADD_ATTRIBUTE_LIST = "custAttributeList",
  CUST_GET_UOM_LIST = "custUomList",
  CUST_GET_PRODUCT_SELECTBOX = "custProductSelectbox",
  CUST_MAPPING_ATTRIBUTE = "custAttributeMapping",
  CUST_GET_ATTRIBUTE_LIST = "custAttrList",
  CUST_GET_PRODUCT_MAKING = "custProductMaking",
  CUST_GET_PRODUCT_COMPOSITION = "custProductComp",
  CUST_GET_PRODUCT_SHAPE = "custProductShape",
  CUST_ADD_PRODUCT = "custAddProduct",
  CUST_GET_PRODUCT_LIST = "custProductList",
  CUST_GET_PRODUCT_DROPDOWN_LIST = "custProductDropDownList",
  CUST_GET_SUPPLIER_LIST = "custGetSupplierListData",
  CUST_GET_SUPPLIER_DROPDOWN_LIST = "custGetSupplierDropDownListData",
  CUST_GET_SUPPLIER_BRAND_DROPDOWN_LIST = "custGetSupplierBrandDropDownListData",
  CUST_GET_SUPPLIER_PLANT_DROPDOWN_LIST = "custGetSupplierPlantDropDownListData",
  CUST_GET_CATEGORY_NEW_LIST = "custGetCategoryListData",
  CUST_GET_CATEGORY_PARENT_LIST = "custGetCategoryParentListData",
  CUST_GET_PRODUCT_BY_CATEGORY_LIST = "custGetProductListByCategoryData",
  CUST_GET_BRAND_BY_CATEGORY_LIST = "custGetBrandListByCategoryData",
  CUST_GET_ADDRESS_TITLE_LIST_DATA = "CustGetAddressTitleListListData",
  CUST_GET_ATTRIBUTE_STRUCTURE_BY_CATEGORY_ID = "custGetAttributeStructureByCategoryId",
  CUST_GET_CAT_CAPABILITY_ATTRIBUTE_STRUCTURE_BY_CATEGORY_ID = "custGetCategoryCapabilityAttributeStructureByCategoryId",
  CUST_GET_ATTRIBUTE_LIST_BY_CATEGORY_ID = "custGetAttributeListByCategoryId",
  CUST_GET_STANDARD_LIST_DATA = "custGetStandardListData",
  CUST_GET_STANDARD_LIST_BY_CASTEGORY_DATA = "custGetStandardListByCategoryData",
  CUST_GET_GRADE_LIST_DATA = "custGetGradeListData",
  CUST_GET_NEW_PRODUCT_LIST = "custGetNewProductList",
  CUST_GET_PRODUCT_DETAILS = "custProductDetails",
  CUST_GET_SALES_INQUIRY_DYNAMIC_PRODUCT_FORM = "custGetSalesInquiryDynamicProductForm",
  CUST_GET_CATEGORY_TREE = "custGetCategoryTree",
  CUST_GET_PARENT_ATTRIBUTE_LIST = "custGetParentAttributeList",
  CUST_ADD_ATTRIBUTE_SETTING = "custAddAttributeSetting",
  CUST_UPDATE_ATTRIBUTE_SETTING = "custUpdateAttributeSetting",
  CUST_GET_INPUT_TABLE_CAT_DATA = "custInputTableData",
  CUST_GET_INPUT_TEXT_CAT_DATA = "custInputTextData",
  CUST_GET_NEXT_DISPLAY_ORDER = "custNxtDisplayOrder",

  CUST_GET_STANDARD_JSON_DATA = "custGetStandardJson",

  CUST_GET_PRODUCT_ATTRIBUTE_STRUCTURE_BY_CATEGORY_ID = "custGetProductAttributeStructureByCategoryId",
  CUST_GET_PRODUCT_STANDARD_JSON_DATA = "custGetProductStandardJson",

  CUST_GET_ATTRIBUTE_SETTING_LIST_BY_ID = "custGetAttributeSettingList",
  CUST_DELETE_CAT_ATTR_SETTINGS = "custDeleteCatAttrSettings",
  CUST_ADD_CAT_ATTR_SETTINGS_VALUE = "custAddCatAttrSettingsValue",
  CUST_GET_ATTRIBUTE_VALUE_SETTING_LIST_BY_ID = "custGetAttributeValueSettingList",


  CUST_UPLOAD_ENTITY_DATA = "custUploadEnityData",
  CUST_LIST_ENTITY_DATA = "custListEnityData",
  CUST_VIEW_ENTITY_DATA = "custViewEnityData",
  CUST_DELETE_ENTITY_DATA = "custDeleteEnityData",

  CUST_UPLOAD_PRICE_DATA = "custUploadPriceData",
  CUST_LIST_PRICE_DATA = "custListPriceData",
  CUST_VIEW_PRICE_DATA = "custViewPriceData",
  CUST_PRODUCT_PRICE_DATA = "custProductPriceData",
  CUST_PRICE_COLLECTOR_DATA = "custCollectorPriceData",
  SALES_UPLOAD_PRICE_DATA = "SalesUploadPriceData",
  SALES_PRICE_TAG_DATA = "SalesPriceTagData",
  SALES_LIST_PRICE_DATA = "salesListPriceData",
  

  CUST_GET_CATEGORY = "custCategorySearch",
  CUST_GET_PRODUCT_UPDATE = "custproductUpdate",
  CUST_GET_PRODUCT_ATTRIBUTE_LIST = "custProductAttributeList",
  CUST_ADD_TAG = "custAddTag",
  CUST_GET_PLANT_CAPACITY_LIST_DATA = "custGetPlantCapacityListData",
  CUST_GET_CATEGORY_MANUFACTURER_LIST_DATA = "custGetCatManufacturerListData",
  CUST_ADD_PLANT_CAPACITY = "custAddPlantCapacity",

  CUST_ADD_PRODUCT_BRAND = "custAddProductBrand",
  CUST_UPDATE_PRODUCT_BRAND = "custUpdateProductBrand",
  CUST_ADD_HSN = "custAddHsn",
  CUST_UPDATE_HSN = "custUpdateHsn",

  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  GET_COMPANY = "company/list",

  CUST_UPDATE_CONTACT = "custUpdateContact",
  CUST_LIST_CONTACT = "custContactList",
  CUST_DESIGNATION_CONTACT = "custDesignationContact",
  CUST_ADD_CONTACT = "custAddContact",

  CUST_ADD_CAT_IMAGE = "custCategoryImage",

  CUST_ADD_STANDARD_ATTRIBUTE = "custStdAttribute",
  CUST_STD_ATTRIBUTE_VALUE = "custStdAttrValue",
  CUST_STD_ATTR_LIST = "custStdAttrList",

  CUST_CAT_STANDARD_DP = "custCatStd",
  CUST_CAT_GRP_DP = "custCatGrp",
  CUST_ADD_STANDARD_CAT = "custAddStdCat",
  CUST_CAT_STANDARD_LIST = "custCatStdList",
  CUST_ADD_CAT_MANUFACTURER = "custAddCatManufacturer",
  CUST_CAT_ATTRIBUTE_SETTING_LIST = "custCatAttrSettingList",

  CUST_PRODUCT_STANDARD_SETTINGS_LIST = "custProStdSettings",
  CUST_PRODUCT_STANDARD_PARENT_DP = "custProStdDp",
  CUST_CREATE_STD_SETTINGS = "custCreateStsSetting",
  CUST_UPDATE_STD_SETTINGS = "custUpdateStsSetting",
  CUST_UPDATE_STD_ATTR_SETTINGS = "custStdAttrSettings",
  CUST_DELETE_ATTR_SETTINGS = "custDeleteAttrSettings",

  CUST_STANDARD_ATTRIBUTE_LIST = "custStdAttributeList",
  CUST_GET_STANDARD_ATTRIBUTE_STRUCTURE_BY_STANDARD_ID = "custGetStdAttributeStructureByStandardId",
  CUST_STANDARD_ATTRIBUTE_LIST_BY_STANDARD_ID = "custStdAttributeByStandardId",
  CUST_STANDARD_ATTRIBUTE_CREATE_BY_ID = "custStdAttributeCreate",
  CUST_GET_PAYMENT_TERM = 'custGetPaymentTerm',
  CUST_GET_PRODUCT_MATERIAL_CONDITION = 'custGetProductMaterialCondition'
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
}

export { Actions, Mutations };
