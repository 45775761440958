import ApiService from "@/core/services/CustomeApiService";
import CustApiService from "@/core/services/CustomApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { ActionsCust, MutationsCust } from "@/store/enums/StoreCustEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  user_first_name: string;
  user_id: number;
  role_id: number;
  user_email: string;
  user_token: string;
  s_id: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class CompanyModule extends VuexModule implements UserAuthInfo {
  errors = "";
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = "";
    JwtService.saveUserData({
      rakesh_count: 0,
      nikhil_count: 0,
      mansi_count: 0,
    });
    JwtService.saveToken(user);
  }

  @Mutation
  [Mutations.SET_USER]() {
    return this.user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = "";
    JwtService.destroyToken();
  }

  @Action
  [Actions.CUST_LOGIN](credentials) {
    ApiService.setHeader();
    return ApiService.post("password_login", credentials)
      .then(({ data }) => {
        if (data.body) {
          this.context.commit(Mutations.SET_AUTH, data.body);
        } else {
          this.context.commit(Mutations.SET_ERROR, data.message);
        }
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CUST_VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      // ApiService.setHeader();
      // ApiService.post("verify_token", payload)
      //   .then(({ data }) => {
      //     this.context.commit(Mutations.SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     this.context.commit(Mutations.SET_ERROR, response.data.errors);
      //     this.context.commit(Mutations.PURGE_AUTH);
      //   });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.CUST_GET_USER_PROFILE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_USER_PROFILE_DP](param) {
    ApiService.setHeader();
    return ApiService.post("company/add_page", param)
      .then(({ data }) => {
        if (data.body != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_USER_PROFILE_PINCODE_SEARCH](param) {
    ApiService.setHeader();
    return ApiService.post("location/search_pincodes", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_USER_PROFILE_GSTIN_SEARCH](param) {
    ApiService.setHeader();
    return ApiService.post("search_gstin", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_USER_PROFILE_GSTIN_INFO](param) {
    ApiService.setHeader();
    return ApiService.post("company/view_gst_info", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_USER_PROFILE_IFSC_SEARCH](param) {
    ApiService.setHeader();
    return ApiService.post("verify_ifsc", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_USER_PROFILE_PAN_SEARCH](param) {
    ApiService.setHeader();
    return ApiService.post("search_pan", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_USER_COMPANY_SEARCH_DP](param) {
    ApiService.setHeader();
    return ApiService.post("company/search_companies_gstin", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_BUSINESS_ENTITY_ADD](param) {
    ApiService.setHeader();
    return ApiService.post("company/create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_BUSINESS_ENTITY_INDIVIDUAL_ADD](param) {
    ApiService.setHeader();
    return ApiService.post("company/create_individual", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_BUSINESS_ENTITY_INDIVIDUAL_UPDATE](param) {
    ApiService.setHeader();
    return ApiService.post("company/update_individual", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_BUSINESS_ENTITY_INDIVIDUAL_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/list_individual", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_UPDATE_ADDRESSES](param) {
    ApiService.setHeader();
    return ApiService.post("company/update_individual_address", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_LIST_ADDRESSES](param) {
    ApiService.setHeader();
    return ApiService.post("company/list_individual_address", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_COMPANY_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_SEARCH_COMPANY_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/search", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_BRANCH_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/branch_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  @Action
  [Actions.CUST_TRANSPORT_GRN_DP](param) {
    ApiService.setHeader();
    return ApiService.post("logistics_management/tn_pending_list", param)
      .then(({ data }) => {
        if (data!= "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_USER_CONTACT_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/contact_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_USER_CONTACT_ADD](param) {
    ApiService.setHeader();
    return ApiService.post("company/contact_create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_USER_CONTACT_UPDATE](param) {
    ApiService.setHeader();
    return ApiService.post("company/contact_update", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PLANT_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/plant_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_USER_BANK_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/list_bank_details", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_USER_BANK](param) {
    ApiService.setHeader();
    return ApiService.post("company/add_bank_details", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_USER_BRAND_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/brand_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_USER_BRAND](param) {
    ApiService.setHeader();
    return ApiService.post("company/brand_add", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_USER_KMP_ADD](param) {
    ApiService.setHeader();
    return ApiService.post("company/kmp_add", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_USER_KMP_UPDATE](param) {
    ApiService.setHeader();
    return ApiService.post("company/kmp_update", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }


  @Action
  [Actions.CUST_GET_WAREHOUSE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/warehouse_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  @Action
  [Actions.CUST_GET_KMP_COMP_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/kmp_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_KMP_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("kmp/list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.KMP_VIEW](param) {
    ApiService.setHeader();
    return ApiService.post("v1/kmp/view", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.KMP_CREATE_UPDATE](param) {
    ApiService.setHeader();
    return ApiService.post("kmp/update", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PINCODE](param) {
    ApiService.setHeader();
    return ApiService.post("location/search_pincodes", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_BRANCH](param) {
    ApiService.setHeader();
    return ApiService.post("company/branch_add", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_EDIT_BRANCH](param) {
    ApiService.setHeader();
    return ApiService.post("company/branch_update", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_COMPANY](param) {
    ApiService.setHeader();
    return ApiService.post("company/create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_KMP](param) {
    ApiService.setHeader();
    return ApiService.post("kmp/create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_LINK_KMP](param) {
    ApiService.setHeader();
    return ApiService.post("company/kmp_add", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_DIN](param) {
    ApiService.setHeader();
    return ApiService.post("kmp/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_DIN_BY_SEARCH](param) {
    ApiService.setHeader();
    return ApiService.post("company/kmp_search_by_din", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_EDIT_BRANCH](param) {
    ApiService.setHeader();
    return ApiService.post("company/branch_edit", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_EDIT_COMPANY](param) {
    ApiService.setHeader();
    return ApiService.post("company/update", param)
      .then(({ data }) => {
        if (!data.is_error) {
          return { data: 1 };
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/common_category/create", param)
      .then(({ data }) => {
        debugger;
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_ATTRIBUTE_SETTING](param) {
    ApiService.setHeader();
    // return ApiService.post("product/category_attribute_setting/create", param)
    return ApiService.post(
      "common_products/category/attributes_setting_create",
      param
    )
      .then(({ data }) => {
        debugger;
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }


  @Action
  [Actions.CUST_UPDATE_ATTRIBUTE_SETTING](param) {
    ApiService.setHeader();
    // return ApiService.post("product/category_attribute_setting/create", param)
    return ApiService.post(
      "common_products/category/attributes_setting_create",
      param
    )
      .then(({ data }) => {
        debugger;
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_INDUSTRY_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_add_page", param)
      .then(({ data }) => {
        if (data.body.industry_types_pc != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_FORM_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_add_page", param)
      .then(({ data }) => {
        if (data.body.form != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_Stage_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_add_page", param)
      .then(({ data }) => {
        if (data.body.stage != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_Length_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_add_page", param)
      .then(({ data }) => {
        if (data.body.length != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_TAGLIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/list_tag", param)
      .then(({ data }) => {
        if (data.data.page_data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_NEXT_DISPLAY_ORDER](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/category/attributes_setting_next_display_order", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_SHAPE_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_add_page", param)
      .then(({ data }) => {
        if (data.body.product != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_INPUT_TABLE_CAT_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/category/attributes_tables", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_INPUT_TEXT_CAT_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/category/attributes_values_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_CATEGORY_DP](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/category/add_page", param)
      .then(({ data }) => {
        if (data.body != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_CATEGORY_LIST](param) {
    ApiService.setHeader();
    // return ApiService.post("product/category_list", param)
    return ApiService.post("product/common_category/list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_CATEGORY_NEW_DB_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/common_category/list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PARENT_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_search", param)
      .then(({ data }) => {
        if (data.data.page_data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_HSN_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/search_hsn", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("HSN");
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_CATEGORY_TREE](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/category_tree_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_PRODUCT_STANDARD_SETTINGS_LIST](param) {
    ApiService.setHeader();
    return ApiService.post(
      "common_products/standard/attributes_setting_list",
      param
    )
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PARENT_ATTRIBUTE_LIST](param) {
    debugger;
    ApiService.setHeader();
    return ApiService.post(
      "common_products/category/attributes_setting_parent_list",
      param
    )
      .then(({ data }) => {
        debugger;
        if (data.data) {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_PRODUCT_STANDARD_PARENT_DP](param) {
    ApiService.setHeader();
    return ApiService.post(
      "common_products/standard/attributes_setting_parent_list",
      param
    )
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_CREATE_STD_SETTINGS](param) {
    ApiService.setHeader();
    return ApiService.post(
      "common_products/standard/attributes_setting_create",
      param
    )
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_UPDATE_STD_SETTINGS](param) {
    ApiService.setHeader();
    return ApiService.post(
      "product/common_product_standard_setting/update",
      param
    )
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_STANDARD_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/common_product_standard/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("StandardList");
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_PRODUCT_STANDARD](param) {
    ApiService.setHeader();
    return ApiService.post("product/common_product_standard/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_UPDATE_STD_ATTR_SETTINGS](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/standard/attributes_setting_create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_DELETE_ATTR_SETTINGS](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/standard/attributes_setting_delete", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_DELETE_CAT_ATTR_SETTINGS](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/category/attributes_setting_delete", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_CAT_ATTR_SETTINGS_VALUE](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/category/attributes_values_create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_STANDARD_MASTER_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/master_standards_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          console.log("StandardList");
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_PRODUCT_GRADE](param) {
    ApiService.setHeader();
    return ApiService.post("product/grade_add", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          console.log("StandardList");
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_search", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_GRADE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/grade_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_UPDATE_CATEGORY](param) {
    ApiService.setHeader();
    return ApiService.post("product/common_category/update", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_UPDATE_PRODUCT_STANDARD](param) {
    ApiService.setHeader();
    return ApiService.post("product/common_product_standard/update", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_UPDATE_GRADE](param) {
    ApiService.setHeader();
    return ApiService.post("product/grade_update", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_GRADE_STANDARD](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_standard_search", param)
      .then(({ data }) => {
        if (data.data.page_data != "") {
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_STANDARD_JSON_DATA](param) {
    ApiService.setHeader();
    return ApiService.post(
      "common_products/category_standard_grade_list",
      param
    )
      .then(({ data }) => {
        if (data.data.page_data != "") {
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_STANDARD_JSON_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/product_standard_grade_list", param)
      .then(({ data }) => {
        if (data.data.page_data != "") {
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_LINK_PRODUCT_STANDARD_CAT](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_standard_mapping_create", param)
      .then(({ data }) => {
        if (data != "") {
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PLANT_CAPACITY_LIST_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/list_capability_matrix", param)
      .then(({ data }) => {
        if (data != "") {
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_CATEGORY_MANUFACTURER_LIST_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/category/manufacturers", param)
      .then(({ data }) => {
        if (data != "") {
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_PLANT_CAPACITY](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/create_capability_matrix", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_ATTRIBUTE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_attribute_mapping_page", param)
      .then(({ data }) => {
        if (data.data.attribute_list != "") {
          console.log("44444444444444");
          console.log(data);
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_MAPPING_ATTRIBUTE](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_attribute_mapping_save", param)
      .then(({ data }) => {
        if (data != "") {
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_ATTRIBUTE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_attribute_mapping_list", param)
      .then(({ data }) => {
        if (data.data.attribute_list != "") {
          console.log(data.data.attribute_list);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_MAKING](param) {
    ApiService.setHeader();
    return ApiService.post("product/add_page", param)
      .then(({ data }) => {
        if (data.body.making_process_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_COMPOSITION](param) {
    ApiService.setHeader();
    return ApiService.post("product/add_page", param)
      .then(({ data }) => {
        if (data.body.composition_types_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_PRODUCT](param) {
    ApiService.setHeader();
    // return ApiService.post("product/product_create", param)
    return ApiService.post("common_products/create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_SHAPE](param) {
    ApiService.setHeader();
    return ApiService.post("product/add_page", param)
      .then(({ data }) => {
        if (data.body.shape_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_list", param)
      .then(({ data }) => {
        if (data != "") {
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_DETAILS](param) {
    ApiService.setHeader();
    // return ApiService.post("common_products/view_product_info_by_id", param)
    return ApiService.post("common_products/view", param)
      .then(({ data }) => {
        if (data != "") {
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_NEW_PRODUCT_LIST](param) {
    ApiService.setHeader();
    // return ApiService.post("common_products/product_list", param)
    return ApiService.post("common_products/list", param)
      .then(({ data }) => {
        if (data != "") {
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_DROPDOWN_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/dp_product_list", param)
      .then(({ data }) => {
        if (data != "") {
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_UPDATE](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_update", param)
      .then(({ data }) => {
        if (data != "") {
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_ATTRIBUTE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/attribute_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          console.log(data.data.result_list);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_COMPANY_PAGE_DATA]() {
    ApiService.setHeader();
    return ApiService.post("company/add_page", {})
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_DESIGNATION_CONTACT](param) {
    ApiService.setHeader();
    return ApiService.post("company/contact_add", param)
      .then(({ data }) => {
        if (data.body.business_natures != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_CONTACT](param) {
    ApiService.setHeader();
    return ApiService.post("company/contact_create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_LIST_CONTACT](param) {
    ApiService.setHeader();
    return ApiService.post("company/contact_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_UPDATE_CONTACT](param) {
    ApiService.setHeader();
    return ApiService.post("company/contact_update", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_TAG](param) {
    ApiService.setHeader();
    return ApiService.post("product/create_tag", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_UOM_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/uom_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ATTRIBUTE_RANGE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("attribute_range/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_CATEGORY_GRADE_MAPPING_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_grade_mapping_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_PRODUCT_ATTRIBUTE_LIST_DISPLAY](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_attribute_mapping_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_PRICE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product_inventory_price/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_PRODUCT_GRADE_MAPPING_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_grade_mapping_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_LINK_ATTRIBUTE_RANGE](param) {
    ApiService.setHeader();
    return ApiService.post("attribute_range/save", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_CAT_GRADE](param) {
    ApiService.setHeader();
    return ApiService.post("product/grade_by_standard", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_CAT_STANDARD](param) {
    ApiService.setHeader();
    return ApiService.post("product/standard_list_dropdown", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_CAT_GRADE](param) {
    ApiService.setHeader();
    return ApiService.post("product/grade_mapping_create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_PRODUCT_BRAND](param) {
    ApiService.setHeader();
    return ApiService.post("product/brand_search", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_PRODUCT_ATTRIBUTE_VALUE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_attribute_json_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_PRODUCT_MAPPING_ATTRIBUTE](param) {
    ApiService.setHeader();
    return ApiService.post("product/attribute_mapping_create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_ATTRIBUTE](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_attribute_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_PRO_GRADE](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_grade_list_dropdown", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_PRO_STANDARD](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_category_standard_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_PRO_GRADE](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_grade_mapping_create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_SELLER_BRANCH_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/get_branches", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("Sellerrrrrrrrrrrrrrrrrr");
          console.log(data.data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_PRODUCT_INCOTERM](param) {
    ApiService.setHeader();
    return ApiService.post("product_incoterm/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("Data");
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_PRODUCT_DELIVERY_TERM](param) {
    ApiService.setHeader();
    return ApiService.post("delivery_term/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("Deliveryyyy");
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_PRODUCT_UOM](param) {
    ApiService.setHeader();
    return ApiService.post("product/uom_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("Data");
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  @Action
  [Actions.CUST_PRODUCT_CURRENCY](param) {
    ApiService.setHeader();
    return ApiService.post("currency/list", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("Data");
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_SELECTBOX](param) {
    ApiService.setHeader();
    return ApiService.post("product/dp_product_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  // @Action
  // [Actions.CUST_ADD_PRODUCT_PRICE](param) {
  //   ApiService.setHeader();
  //   return ApiService.post("product/price_save",param)
  //     .then(({ data }) => {
  //       if(data.data != ""){
  //         console.log("Data")
  //         console.log(data)
  //         return data
  //       }else{
  //         return {"data" : 0}
  //       }
  //     })
  //     .catch(({ response }) => {
  //       return {"data" : 0}
  //     });
  // }
  @Action
  [Actions.CUST_ADD_PRODUCT_INVENTORY](param) {
    ApiService.setHeader();
    return ApiService.post("product_inventory_price/save", param)
      .then(({ data }) => {
        if (data.data != "") {
          console.log("Data");
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_BRAND](param) {
    ApiService.setHeader();
    return ApiService.post("company/brand/list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_HSN_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/hsn_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_PRODUCT_BRAND](param) {
    ApiService.setHeader();
    return ApiService.post("company/brand/create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  @Action
  [Actions.CUST_UPDATE_PRODUCT_BRAND](param) {
    ApiService.setHeader();
    return ApiService.post("company/brand/update", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_HSN](param) {
    ApiService.setHeader();
    return ApiService.post("product/hsn_create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_UPDATE_HSN](param) {
    ApiService.setHeader();
    return ApiService.post("product/hsn_update", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_CAT_IMAGE](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_image_upload", param)
      .then(({ data }) => {
        debugger;
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_SUPPLIER_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/list_by_tradetype", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_ATTRIBUTE_STRUCTURE_BY_CATEGORY_ID](param) {
    ApiService.setHeader();
    // return ApiService.post("common_products/attribute_structure_by_category_id", param)
    return ApiService.post(
      "common_products/category/attributes_form_structure",
      param
    )

      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_CAT_CAPABILITY_ATTRIBUTE_STRUCTURE_BY_CATEGORY_ID](param) {
    ApiService.setHeader();
    return ApiService.post(
      "common_products/category_capability_structure_form",
      param
    )

      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_ATTRIBUTE_STRUCTURE_BY_CATEGORY_ID](param) {
    ApiService.setHeader();
    return ApiService.post(
      "common_products/category_attributes_structure_form",
      param
    )

      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/search", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_SUPPLIER_BRAND_DROPDOWN_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/search_brands", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_SUPPLIER_PLANT_DROPDOWN_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("company/plant_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_ADDRESS_TITLE_LIST_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("company/dp_branchtitletype_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_CATEGORY_NEW_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/categories_by_parent_id", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_CATEGORY_PARENT_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/category/list_with_parent", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_BY_CATEGORY_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/products_by_category_id", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_BRAND_BY_CATEGORY_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/brands_by_category", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_SALES_INQUIRY_DYNAMIC_PRODUCT_FORM](param) {
    ApiService.setHeader();
    return ApiService.post(
      "common_products/category_attributes_structure",
      param
    )
      .then((data) => {
        if (data) {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_STANDARD_LIST_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("product/procategory_standard_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_STANDARD_LIST_BY_CASTEGORY_DATA](param) {
    ApiService.setHeader();
    return ApiService.post(
      "common_products/category/standards_mapping_dropdown",
      param
    )
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_GRADE_LIST_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_standard_attribute_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_ATTRIBUTE_LIST_BY_CATEGORY_ID](param) {
    ApiService.setHeader();
    // return ApiService.post("common_products/category_attributes_list", param)
    return ApiService.post("common_products/category/attributes_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_ATTRIBUTE_SETTING_LIST_BY_ID](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/category/attributes_setting_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_ATTRIBUTE_VALUE_SETTING_LIST_BY_ID](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/category/attributes_values", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_STANDARD_ATTRIBUTE](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_standard_attribute_create", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_STD_ATTRIBUTE_VALUE](param) {
    ApiService.setHeader();
    return ApiService.post("product/standard_attribute_group_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_STD_ATTR_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/product_standard_attribute_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_CAT_STANDARD_DP](param) {
    ApiService.setHeader();
    // return ApiService.post("product/product_standard_search", param)
    return ApiService.post("common_products/standard/search_dropdown", param)
      .then(({ data }) => {
        if (data.data.page_data != "") {
          console.log(data);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_CAT_GRP_DP](param) {
    ApiService.setHeader();
    return ApiService.post("product/standard_attribute_group_list", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_STANDARD_CAT](param) {
    ApiService.setHeader();
    // return ApiService.post("product/product_category_standard_create", param)
    return ApiService.post("common_products/category/standards_mapping", param)
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_ADD_CAT_MANUFACTURER](param) {
    ApiService.setHeader();
    // return ApiService.post("product/product_category_standard_create", param)
    return ApiService.post(
      "common_products/category/manufacturer_mapping_create",
      param
    )
      .then(({ data }) => {
        if (data.data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_CAT_STANDARD_LIST](param) {
    ApiService.setHeader();
    // return ApiService.post("product/procategory_standard_list", param)
    return ApiService.post(
      "common_products/category/standards_mapping_list",
      param
    )
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_CAT_ATTRIBUTE_SETTING_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/category/attributes_setting_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_STANDARD_ATTRIBUTE_STRUCTURE_BY_STANDARD_ID](param) {
    ApiService.setHeader();
    // return ApiService.post("common_products/category_attributes_list", param)
    return ApiService.post(
      "common_products/standard/attributes_form_structure",
      param
    )
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_STANDARD_ATTRIBUTE_LIST](param) {
    ApiService.setHeader();
    return ApiService.post("product/category_attribute_mapping_list", param)
      .then(({ data }) => {
        if (data.data.attribute_list != "") {
          console.log(data.data.attribute_list);
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_STANDARD_ATTRIBUTE_CREATE_BY_ID](param) {
    ApiService.setHeader();
    return ApiService.post("common_products/standard/attributes_create", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }


  @Action
  [Actions.CUST_STANDARD_ATTRIBUTE_LIST_BY_STANDARD_ID](param) {
    ApiService.setHeader();
    // return ApiService.post("common_products/category_attributes_list", param)
    return ApiService.post("common_products/standard/attributes_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_LIST_ENTITY_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("company/list_upload_records", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  
  @Action
  [Actions.CUST_UPLOAD_ENTITY_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("company/bulk_update", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  
  @Action
  [Actions.CUST_VIEW_ENTITY_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("company/view_upload_record", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_DELETE_ENTITY_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("company/delete_record", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
    [Actions.DOCUMENT_DP](param) {
        ApiService.setHeader();
        return ApiService.post("company/common_document_list",param)
        .then(({ data }) => {
          if(data.data != ""){  
            return data
          }else{
            return {"data" : 0}
          }
        })
        .catch(({ response }) => {
          return {"data" : 0}
        });
    }
    
    @Action
    [Actions.DOCUMENT_UPLOAD](param) {
        ApiService.setHeader();
        return ApiService.post("company/contact_document_verification",param)
        .then(({ data }) => {
          if(data.data != ""){  
            return data
          }else{
            return {"data" : 0}
          }
        })
        .catch(({ response }) => {
          return {"data" : 0}
        });
    }

    @Action
    [Actions.DOCUMENT_COMPANY_UPLOAD](param) {
        ApiService.setHeader();
        return ApiService.post("company/company_document_verification",param)
        .then(({ data }) => {
          if(data.data != ""){  
            return data
          }else{
            return {"data" : 0}
          }
        })
        .catch(({ response }) => {
          return {"data" : 0}
        });
    }
    
    @Action
    [Actions.DOCUMENT_LIST](param) {
        ApiService.setHeader();
        return ApiService.post("company/contact_document_list",param)
        .then(({ data }) => {
          if(data.data.result_list != ""){  
            return data
          }else{
            return {"data" : 0}
          }
        })
        .catch(({ response }) => {
          return {"data" : 0}
        });
    }

    @Action
    [Actions.DOCUMENT_COMPANY_LIST](param) {
        ApiService.setHeader();
        return ApiService.post("company/company_document_list",param)
        .then(({ data }) => {
          if(data.data.result_list != ""){  
            return data
          }else{
            return {"data" : 0}
          }
        })
        .catch(({ response }) => {
          return {"data" : 0}
        });
    }

  @Action
  [Actions.CUST_LIST_PRICE_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("research_price_index/price_upload_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  
  @Action
  [Actions.CUST_UPLOAD_PRICE_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("research_price_index/price_bulk_upload", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_VIEW_PRICE_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("research_price_index/price_upload_view", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_PRODUCT_PRICE_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("research_common/product_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_PRICE_COLLECTOR_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("research_price_index/price_collector_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  
  @Action
  [Actions.SALES_PRICE_TAG_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("research_price_index/price_upload_tags_list", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.SALES_UPLOAD_PRICE_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("research_price_index/price_collector_uploads", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
  
  @Action
  [Actions.SALES_LIST_PRICE_DATA](param) {
    ApiService.setHeader();
    return ApiService.post("research_price_index/price_collector_uploads_list", param)
      .then(({ data }) => {
        if (data.data.result_list != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PAYMENT_TERM](param) {
    ApiService.setHeader();
    return ApiService.post("payment_terms", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }

  @Action
  [Actions.CUST_GET_PRODUCT_MATERIAL_CONDITION](param) {
    ApiService.setHeader();
    return ApiService.post("product_material_condition", param)
      .then(({ data }) => {
        if (data != "") {
          return data;
        } else {
          return { data: 0 };
        }
      })
      .catch(({ response }) => {
        return { data: 0 };
      });
  }
}
